import React from 'react'
import { View, StyleSheet, Text } from '@react-pdf/renderer'

import * as theme from '../theme'

export const commonStyles = StyleSheet.create({
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    textTransform: 'uppercase',
    fontSize: theme.fontSize.pageTitle,
    fontWeight: 'bold',
  },
  subtitle: {
    fontSize: theme.fontSize.pageSubtitle,
    fontWeight: 'normal',
  },
  documentHeader: {
    backgroundColor: theme.colors.documentHeaderBg,
    color: theme.colors.documentHeader,
    fontWeight: 'bold',
  },
  detailsRoot: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  details: {
    fontSize: theme.fontSize.text,
    fontWeight: 'normal',
    paddingBottom: theme.padding(2),
    paddingTop: theme.padding(1),
  },
  padding: {
    paddingBottom: 0,
  },
})

export const renderTitleContainer = (
  title: string,
  subtitle: string,
  customStyle: { paddingBottom: number } | object = {},
): JSX.Element => (
  <View style={[commonStyles.titleContainer, customStyle]}>
    <Text style={commonStyles.title}>{title}</Text>
    <Text style={commonStyles.subtitle}>{subtitle}</Text>
  </View>
)

export const renderDetails = (details: string): JSX.Element => (
  <View style={commonStyles.detailsRoot}>
    <Text style={commonStyles.details}>{details}</Text>
  </View>
)
