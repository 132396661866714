import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import { enUS, fr } from 'date-fns/locale'
import { i18nextPlugin as showTranslationsPlugin } from 'translation-check'

import frenchTranslationFile from './fr/french.json'
import englishTranslationFile from './en/english.json'

export const dateFnsLocales = {
  en: enUS,
  fr,
}

export const resources = {
  fr: {
    translation: frenchTranslationFile,
  },
  en: {
    translation: englishTranslationFile,
  },
} as const

i18n
  .use(initReactI18next)
  .use(showTranslationsPlugin)
  .init({
    resources: resources,
    lng: localStorage.getItem('language') ?? 'en',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // react already safes from xss injection
    },
    parseMissingKeyHandler: (path: string) => {
      if (process.env.NODE_ENV === 'development')
        return `{{${i18n.language}:${path}}}`
      const index = path.lastIndexOf('.') + 1
      const defaultValue = path.substr(index).replace(/_/g, ' ')
      return defaultValue
    },
  })
  .finally(() => {})
