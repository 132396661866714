import React from 'react'
import { View, Text, StyleSheet } from '@react-pdf/renderer'
import { useTranslation } from 'react-i18next'

import * as theme from './theme'

const styles = StyleSheet.create({
  container: {
    padding: theme.padding(2),
  },
  title: {
    ...theme.styles.title,
  },
  content: {
    padding: theme.padding(1),
  },
})

export type TProps = React.PropsWithChildren<{
  title: string | undefined
  bgMain?: string
  variant?: 'CheckIn' | 'CheckOut'
}>

export default ({
  title,
  bgMain = theme.colors.sectionOddBg,
  children,
  variant,
}: TProps): JSX.Element => {
  const { t } = useTranslation()

  return (
    <View
      style={[
        styles.container,
        {
          backgroundColor: bgMain,
        },
        {
          color:
            variant === 'CheckIn'
              ? '#2ECFAD'
              : variant === 'CheckOut'
              ? '#F44336'
              : 'black',
        },
      ]}
    >
      <Text style={styles.title}>
        {typeof title === 'string' && `${t(title)} :`}
      </Text>
      <View style={styles.content}>{children}</View>
    </View>
  )
}
