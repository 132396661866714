import React from 'react'
import { Text } from '@react-pdf/renderer'

import styles from './styles'
import { TColumn } from './Table'
import { toStyleArray } from './utils'

const HeaderCell = <Cols extends string>({
  column,
}: {
  column: TColumn<Cols, unknown>
}): JSX.Element => {
  return (
    <Text
      style={[styles.cell, styles.headerCell, ...toStyleArray(column.style)]}
    >
      {column.title}
    </Text>
  )
}

export default HeaderCell
