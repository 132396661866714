import React from 'react'
import { View, StyleSheet } from '@react-pdf/renderer'
import { useTranslation } from 'react-i18next'

import { commonStyles, renderTitleContainer } from './CommonUtils'
import shortenUUID from 'src/utils/shortenUUID'
import { TAlarmReportProps } from '../Provider'
import * as theme from '../theme'

const alarmStyles = StyleSheet.create({
  ...commonStyles,
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.colors.documentHeaderBg,
    color: theme.colors.documentHeader,
    fontWeight: 'bold',
  },
  padding: {
    padding: theme.spacing(2),
  },
})

const AlarmTitle: React.FC<TAlarmReportProps> = ({
  entity,
}: TAlarmReportProps): JSX.Element => {
  const { t } = useTranslation()

  return (
    <View style={alarmStyles.root}>
      {renderTitleContainer(
        t('alarm_report.header.title'),
        shortenUUID(entity.id),
        alarmStyles.padding,
      )}
    </View>
  )
}

export default AlarmTitle
