// @ts-nocheck

import { Font } from '@react-pdf/renderer'
import RobotoBold from 'src/assets/fonts/Roboto-Bold.ttf'
import RobotoLight from 'src/assets/fonts/Roboto-Light.ttf'
import RobotoRegular from 'src/assets/fonts/Roboto-Regular.ttf'

/** needs to be called before any react-pdf including this font is rendered */
export default () => {
  Font.register({
    family: 'Roboto',
    fonts: [
      { src: RobotoRegular }, // font-style: normal, font-weight: normal
      { src: RobotoLight, fontWeight: 'light' },
      { src: RobotoBold, fontWeight: 'bold' },
    ],
  })
}
