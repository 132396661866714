import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { Loading } from 'react-admin'
import { useTranslation } from 'react-i18next'
import qs from 'query-string'
import { Typography } from '@material-ui/core'
import { Alert, AlertTitle } from '@material-ui/lab'
import api from 'src/utils/api'

const ExternalPDF = ({
  id,
  getData,
  Provider,
  ViewerComponent,
  errorTitle,
  loadingPrimary,
}): JSX.Element => {
  const { i18n } = useTranslation()
  const location = useLocation()
  const { lang, authorization } = qs.parse(location.search)
  const browserLanguage = navigator.language

  const [data, getDataAction] = api.useApiAction(getData)

  useEffect(() => {
    if (typeof authorization !== 'string') {
      return
    }

    const language =
      typeof lang === 'string' && /^(fr|en)(-.*)?/i.test(lang)
        ? lang.toLowerCase().split('-')
        : browserLanguage

    if (language.includes('fr')) void i18n.changeLanguage('fr')
    else void i18n.changeLanguage('en')

    return getDataAction({
      id,
      token: authorization,
    })
  }, [
    location.search,
    id,
    authorization,
    i18n,
    lang,
    browserLanguage,
    getDataAction,
  ])

  if (data.error !== null || typeof authorization !== 'string') {
    return (
      <Alert severity='error'>
        <AlertTitle>{errorTitle}</AlertTitle>
        <Typography>
          {data.error?.message ?? 'Invalid authorization'}
        </Typography>
      </Alert>
    )
  }

  if (!data.loaded) {
    return (
      <Loading
        loadingPrimary={loadingPrimary}
        loadingSecondary=''
      />
    )
  }

  return (
    <Provider
      authorization={authorization}
      entity={data.data}
    >
      <ViewerComponent />
    </Provider>
  )
}

export default ExternalPDF
