import React from 'react'
import { Text } from '@react-pdf/renderer'
import { useTranslation } from 'react-i18next'

import * as theme from './theme'

export type TProps = {
  text?: string
}

const NoContent = ({ text = undefined }: TProps): JSX.Element => {
  const { t } = useTranslation()

  return (
    <Text style={theme.styles.textSecondary}>
      {text ?? t('alarm_report.no_content')}
    </Text>
  )
}

export default NoContent
