import React from 'react'
import { Text } from '@react-pdf/renderer'

import NoContent from 'src/parser/shared/PDF/NoContent'
import * as theme from 'src/parser/shared/PDF/theme'
import { TSectionContent } from 'src/parser/shared/PDF/Sections'
import { TEntity } from 'src/utils/api'
import { IAlarm } from '@obvious.tech/constellation'

const Description: TSectionContent = ({ entity }) => {
  const alarm = entity as TEntity<IAlarm>
  if (!alarm.description) return <NoContent />

  return <Text style={theme.styles.text}>{alarm.description}</Text>
}

export default Description
