import React from 'react'
import { Text, Image } from '@react-pdf/renderer'
import { useTranslation } from 'react-i18next'

import * as theme from 'src/parser/shared/PDF/theme'
import NoContent from 'src/parser/shared/PDF/NoContent'
import { TSectionContent } from 'src/parser/shared/PDF/Sections'

const Location: TSectionContent = ({ entity, map }) => {
  const { t } = useTranslation()
  const { location } = entity

  if (!location?.latitude || !location?.longitude) {
    return <NoContent />
  }

  return (
    <>
      <Text>
        <Text>
          <Text style={theme.styles.subtitle}>
            {`${t('alarm_report.location.latitude')} : `}
          </Text>
          <Text style={theme.styles.text}>
            {t('alarm_report.location.lat_lng_format', {
              value: location.latitude,
            })}
          </Text>
          <Text style={theme.styles.subtitle}>
            {` ; ${t('alarm_report.location.longitude')} : `}
          </Text>
          <Text style={theme.styles.text}>
            {t('alarm_report.location.lat_lng_format', {
              value: location.longitude,
            })}
          </Text>
        </Text>
      </Text>
      {map && (
        <Image
          source={map}
          style={{
            width: '100%',
            alignSelf: 'center',
            marginTop: theme.padding(1),
          }}
        />
      )}
    </>
  )
}

export default Location
