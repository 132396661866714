import React from 'react'
import { View, StyleSheet, Text } from '@react-pdf/renderer'
import { useTranslation } from 'react-i18next'

import * as theme from '../theme'
import { TAlarmReportProps, TAgentReportProps } from '../Provider'

const commonStyles = StyleSheet.create({
  root: {
    color: theme.colors.documentSubHeader,
    padding: theme.padding(2),
    display: 'flex',
    flexDirection: 'row',
  },
  column: {
    flex: 1,
    flexDirection: 'column',
  },
  fieldTitle: {
    ...theme.styles.subtitle,
    color: 'white',
  },
  fieldContent: {
    ...theme.styles.text,
    color: 'white',
  },
  alarmBackgroundColor: {
    backgroundColor: theme.colors.documentSubHeaderBg,
  },
  activityReportBackgroundColor: {
    backgroundColor: theme.colors.activityReportSubHeaderBg,
  },
})

type TFieldProps = {
  title: string
  children: React.ReactNode
}

const Field: React.FC<TFieldProps> = ({ title, children }): JSX.Element => (
  <Text>
    <Text style={commonStyles.fieldTitle}>{`${title}: `}</Text>
    <Text style={commonStyles.fieldContent}>{children}</Text>
  </Text>
)

type CommonHeaderProps = {
  children: React.ReactNode
  customStyle: { paddingBottom: number } | object
}

const CommonHeader: React.FC<CommonHeaderProps> = ({
  children,
  customStyle = {},
}): JSX.Element => (
  <View style={[commonStyles.root, customStyle]}>{children}</View>
)

const renderField = (title: string, content: React.ReactNode): JSX.Element => (
  <Field title={title}>{content}</Field>
)

export const AgentActivityHeader: React.FC<TAgentReportProps> = ({
  entity,
  assignedPassport,
}: TAgentReportProps): JSX.Element | null => {
  const { t } = useTranslation()

  // @ts-expect-error
  const agentDetails = assignedPassport[0]

  return (
    <CommonHeader
      customStyle={{
        backgroundColor:
          commonStyles.activityReportBackgroundColor.backgroundColor,
      }}
    >
      <View style={commonStyles.column}>
        {renderField(t('agent_activity_report.header.id'), entity.id)}
        {renderField(
          t('agent_activity_report.header.creation_date'),
          typeof entity.checkIn === 'object' &&
            t('agent_activity_report.header.creation_date_format', {
              date: new Date(entity.checkIn.timestamp),
            }),
        )}
        {renderField(
          t('agent_activity_report.header.organisation'),
          agentDetails.organization,
        )}
      </View>
      <View style={commonStyles.column}>
        {renderField(
          t('agent_activity_report.header.first_name'),
          agentDetails.firstname,
        )}
        {renderField(
          t('agent_activity_report.header.last_name'),
          agentDetails?.lastname,
        )}
        {renderField(t('agent_activity_report.header.job'), agentDetails.job)}
      </View>
    </CommonHeader>
  )
}

export const AlarmHeader: React.FC<TAlarmReportProps> = ({
  entity,
  assignedPassport,
}: TAlarmReportProps): JSX.Element => {
  const { t } = useTranslation()

  return (
    <CommonHeader
      customStyle={{
        backgroundColor: commonStyles.alarmBackgroundColor.backgroundColor,
      }}
    >
      <View style={commonStyles.column}>
        {renderField(t('alarm_report.header.id'), entity.id)}
        {renderField(
          t('alarm_report.header.creation_date'),
          t('alarm_report.header.creation_date_format', {
            date: new Date(entity.creationDate),
          }),
        )}
        {renderField(
          t('alarm_report.header.originator_system'),
          entity.originatorSystem,
        )}
        {renderField(
          t('alarm_report.header.assigned_user'),
          assignedPassport?.username,
        )}
      </View>
      <View style={commonStyles.column}>
        {renderField(t('alarm_report.header.status'), entity.status)}
        {renderField(t('alarm_report.header.category'), entity.category)}
        {renderField(t('alarm_report.header.severity'), entity.severity)}
        {renderField(t('alarm_report.header.certainty'), entity.certainty)}
      </View>
    </CommonHeader>
  )
}
